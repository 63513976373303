.footer{
    background: #F9F9F9;
    padding-top: 70px;
    padding-bottom: 60px;
}
.footerTitle{
font-size: 20px;
font-weight: 700;
line-height: 20.9px;
text-align: left;
padding-bottom: 7px;
font-family: 'Montserrat';
}
.footerDescription{
font-size: 15px;
font-weight: 500;
line-height: 21.6px;
text-align: left;
padding-top: 15px;
font-family: 'Montserrat';

}

@media (min-width: 768px) {
    .absl{
        position: relative;
        right: 30px;
    }
}
.footerEmailField{
    border: 1px solid #94949480;
    border-radius: 1000px;
    max-width: 488px;
    display: flex;
    float: left;
    background-color: white;
    padding: 6px;
}
.roundBorder{
    border-radius: 1000px;
    margin-right: 1px;
}
.button{
    width: Hug (129px)px;
height: Hug (45px)px;
padding: 8px 24px 8px 24px;
border-radius: 24px;
background: var(--Main, #3A86FF);
color: white;
}
.footerIcon{
    padding-right: 40px;
}
.copyright{

font-size: 13.5px;
font-weight: 400;
line-height: 21.6px;
text-align: left;
padding-top: 15px;
}

@media (max-width:768px) {
    .footer{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 30px;
    }
}

@media (max-width:1024px) {
    .footer{
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 30px;
    }

    .footerTitle{
        font-size: 15px;
        font-weight: 700;
        line-height: 20.9px;
        text-align: left;
        padding-bottom: 7px;
        font-family: 'Montserrat';
        }
        .footerDescription{
        font-size: 15px;
        font-weight: 500;
        line-height: 21.6px;
        text-align: left;
        padding-top: 15px;
        font-family: 'Montserrat';
        
        }
}