.data-deletion-page {
  max-width: 500px;
  margin: 50px auto;
  padding: 50px 20px;
}

.data-deletion-page .page-title {
  font-family: "DM Sans";
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.data-deletion-page .data-deletion-form {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.data-deletion-form label {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 26.04px;
  text-align: left;
}

.data-deletion-form label span {
  color: red;
}

.data-deletion-page .data-deletion-form .form-input {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid gray;
}

.data-deletion-page .data-deletion-form .form-input::placeholder,
.data-deletion-page .data-deletion-form .form-text::placeholder {
  font-size: 14px;
}

.data-deletion-page .data-deletion-form .form-text {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 8px;
  resize: none;
  border: 1px solid gray;
}

.data-deletion-page .data-deletion-form .form-submit {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  background: var(--Main, #3a86ff);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-deletion-page .form-submit:hover {
  transform: scale(1.01);
}

.data-deletion-page .data-deletion-form .form-submit .loading-anim {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #555;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .data-deletion-page .page-title {
    font-family: "DM Sans";
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    margin-bottom: 20px;
  }
}
