.cb{
    font-size: 14px;
    font-weight: 500;
    line-height: 18.23px;
    text-align: center;
    color: #3A86FF;
    margin-bottom: 12px;
}
.sol-one-heading{
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 12px;
    font-family: "Montserrat", sans-serif;
}
.syj{
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
}

@media (max-width: 768px) {
    .sol-one-heading{
        font-size: 22px;
        font-weight: 700;
        line-height: 21.94px;
        text-align: center;
        margin-bottom: 12px;
        font-family: "Montserrat", sans-serif;
    }
    .syj{
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        margin-bottom: 20px;
    }
}

@media (min-width: 769px) and (max-width: 1023px) {
    .cb{
        font-size: 14px;
        font-weight: 500;
        line-height: 18.23px;
        text-align: center;
        color: #3A86FF;
        margin-bottom: 12px;
    }
    .sol-one-heading{
        font-size: 26px;
        font-weight: 700;
        line-height: 48px;
        text-align: center;
        margin-bottom: 12px;
        font-family: "Montserrat", sans-serif;
    }
    .syj{
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .cb{
        font-size: 14px;
        font-weight: 500;
        line-height: 18.23px;
        text-align: center;
        color: #3A86FF;
        margin-bottom: 12px;
    }
    .sol-one-heading{
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        text-align: center;
        margin-bottom: 12px;
        font-family: "Montserrat", sans-serif;
    }
    .syj{
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
    }
}