.navbar-item{
    font-size: 14px;
    font-weight: 300;
    margin-left: 20px;
    margin-right: 20px;
}
.vempty{
    width:95.53px
}
.drawer{
    padding: 30px;
    padding-top: 45px;
}
.drawer-icon{
    
}
.menu-head{
    padding-bottom: 45px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Montserrat';
}
.menu-item{
    padding-bottom: 25px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Montserrat';
    cursor: pointer;
}
.plain-link {
    text-decoration: none;
    color: inherit;
    font-weight: 500;
  }

  .plain-link:hover {
    text-decoration: none;
    color: grey;
  }
