.body-wrap {
  width: 100%;
  margin: 0 auto;
}

.body-feature-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.body-main-des {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
  font-family: "DM Sans";
}
.body-main-title {
  font-family: "DM Sans";
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 48.76px;
  text-align: left;
}
.body-main-item {
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .body-feature-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    /* margin-bottom: 40px; */
  }
  .body-main-des {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    font-family: "DM Sans";
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
  .body-main-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: "DM Sans";
  }
  .body-main-item {
    width: 100%;
    margin: 0px;
  }
  .body-main-item2 {
    margin: 0;
    width: 100%;
  }
  .mgt {
    margin-bottom: 0;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .body-main-des {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    font-family: "DM Sans";
    text-align: left;
    margin-bottom: 20px;
  }
  .body-main-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    margin-bottom: 5px;
    font-family: "DM Sans";
  }
  .body-main-item {
    width: calc(100vw / 3.3);
    height: calc(100vw / 3.3);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .mgt {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .body-main-des {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    font-family: "DM Sans";
    margin-bottom: 20px;
  }
  .body-main-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin-bottom: 5px;
    font-family: "DM Sans";
  }
  .body-main-item {
    width: calc(100vw / 2.6);
    height: calc(100vw / 2.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .body-main-item2 {
    width: 100%;
  }
  .mgt {
    margin-bottom: 0;
  }
}
