.textField textarea {
  resize: none;
}

.submit-div {
  position: relative;
}

.submit-button {
  padding: 15px 70px 15px 70px;
  border-radius: 15px;
  font-weight: 500;
  background: var(--Main, #3a86ff);
  color: white;
  font-size: 24px;
  font-family: "DM Sans";
  position: absolute;
  right: 0px;
}

.attachment-container {
  font-family: "DM Sans";
  background: transparent;
  height: 100px;
  border: 1px solid #94949463;
  border-radius: 8px;
  padding: auto;
  display: flex;
  padding-top: 15px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.attachment-container p {
  text-align: center;
  color: grey;
}

.attachment-container span {
  font-weight: 600;
  color: #000;
}
.userform-label {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 26.04px;
  text-align: left;
}
.ib {
  height: 80px;
}
.emailField2 {
  border: 1px solid #63626263;
  border-radius: 8px;
}
.textField {
  border: 1px solid #63626263;
  border-radius: 8px;
}
.emailField3 {
  border-radius: 20px;
}
.iw2 {
  max-width: 70%;
}

.custom-select-dropdown {
  width: 100%;
  height: 80px;
  border-radius: 20px;
  border: 1px solid #63626263;
}
.custom-select-dropdown div {
  padding: 4px;
  font-size: 16px;
  font-weight: 600;
  font-family: "DM Sans";
}

.custom-select-dropdown ::placeholder {
  font-weight: 600;
  font-size: 17px;
  font-weight: 700;
  font-family: "DM Sans";
  text-decoration: none;
  opacity: 100%;
}

.files-container {
}

.user-form-close-button {
  background-color: transparent;
  border: none;
  padding: 4px;
  margin-left: 15px;
  color: red;
}
.error {
  color: red;
  font-size: 17px;
  font-weight: 700;
  font-family: "DM Sans";
}

ul.files-list {
  list-style: none; /* Remove default bullets */
}

.files-container li::before {
  content: "•"; /* Replace "•" with your desired bullet character */
  color: #3a86ff;
  font-size: 1.2em;
  margin-left: -1em;
  margin-right: 0.25em;
}

@media (max-width: 768px) {
  .iw2 {
    max-width: 95%;
  }

  textarea::placeholder {
    font-size: 15px;
  }

  .custom-select-dropdown {
    width: 100%;
    height: 60px;
    border-radius: 3px;
    border: 1px solid #63626263;
  }
  .custom-select-dropdown div {
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    font-family: "DM Sans";
  }

  .custom-select-dropdown ::placeholder {
    font-weight: 600;
    font-size: 14px;
    font-weight: 700;
    font-family: "DM Sans";
    text-decoration: none;
    opacity: 100%;
  }

  .userform-label {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 600;
    line-height: 26.04px;
    text-align: left;
  }

  .ib {
    height: 60px;
  }

  .emailField2 {
    border: 1px solid #15151563;
    border-radius: 3px;
  }

  .submit-button {
    padding: 15px 110px 15px 110px;
    border-radius: 8px;
    font-weight: 600;
    background: var(--Main, #3a86ff);
    color: white;
    font-size: 17px;
    font-family: "DM Sans";
    position: relative;
    width: 100%;
  }

  .error {
    color: red;
    font-size: 13px;
    font-weight: 700;
    font-family: "DM Sans";
  }

  .attachment-container {
    font-family: "DM Sans";
    background: transparent;
    height: 70px;
    border: 1px solid #94949463;
    border-radius: 8px;
    padding: auto;
    display: flex;
    padding-top: 15px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 400px) {
  textarea::placeholder {
    font-size: 15px;
  }
  .iw2 {
    max-width: 95%;
  }
  .custom-select-dropdown {
    width: 100%;
    height: 55px;
    border-radius: 2px;
    border: 1px solid #63626263;
  }
  .custom-select-dropdown div {
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    font-family: "DM Sans";
  }

  .custom-select-dropdown ::placeholder {
    font-weight: 600;
    font-size: 14px;
    font-weight: 700;
    font-family: "DM Sans";
    text-decoration: none;
    opacity: 100%;
  }

  .userform-label {
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 26.04px;
    text-align: left;
  }
  .ib {
    height: 40px;
  }
  .emailField2 {
    border: 1px solid #15151563;
    border-radius: 3px;
    height: 50px;
  }
  .emailField3 {
    border-radius: 20px;
  }
  .submit-button {
    padding: 15px 110px 15px 110px;
    border-radius: 8px;
    font-weight: 600;
    background: var(--Main, #3a86ff);
    color: white;
    font-size: 17px;
    font-family: "DM Sans";
    position: relative;
    width: 100%;
  }
  .error {
    color: red;
    font-size: 13px;
    font-weight: 700;
    font-family: "DM Sans";
  }

  .attachment-container {
    font-family: "DM Sans";
    background: transparent;
    height: 65px;
    border: 1px solid #94949463;
    border-radius: 8px;
    padding: auto;
    display: flex;
    padding-top: 15px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }
}
