.full-screen-image {
  width: 100;
  padding: 0;
  margin-left: -100px;
  margin-right: -100px;
}

.inpay-special {
  width: calc(100vw - 20px);
  margin-left: calc(-50vw + 50% + 10px);
  height: 500px;
  background-color: #408aff;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.inpay-special::before {
  content: "";
  height: 864px;
  background-image: url("../../../assets/images/inpay-special-dots.svg");
  background-repeat: repeat;
  background-size: 750px auto;
  opacity: 0.55;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.inpay-special .phone-image {
  position: absolute;
  top: 105px;
  right: calc(-50vw + 50% + 80px);
  z-index: 1;
}

.inpay-special-text-div {
  position: absolute;
  top: 77px;
  left: calc(-50vw + 50% + 80px);
  width: calc(100vw / 2.1);
  z-index: 1;
}

.inpay-special-text-title {
  font-family: "DM Sans";
  font-size: 60px;
  font-weight: 700;
  line-height: 78.12px;
  text-align: left;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 55px;
}

.inpay-special-text-subtitle {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
}

.inpay-special-text-body {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
}

.inpay-special-text-button {
  z-index: 2;
  position: absolute;
  bottom: 45px;
  left: calc(-50vw + 50% + 80px);
}

.btn-des {
  font-family: "DM Sans";
  font-size: 25px;
  font-weight: 600;
  line-height: 30.04px;
  color: #3a86ff;
}

.ibtn {
  width: 400px;
  height: 70px;
  border-radius: 15px;
}

.store-img {
  width: 24px;
  height: 24px;
}
.store-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1px;
  align-items: center;
}

.store-text-wrap .subtitle {
  font-size: 10px;
  color: #475467;
  font-family: Inter;
  font-weight: 400;
  line-height: 14px;
}

.store-text-wrap .title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #3a86ff;
}

@media (max-width: 768px) {
  .full-screen-image {
    width: 100vw;
    padding: 0;
  }
  .inpay-special {
    position: relative;
    height: 925px;
    padding: 0;
    overflow: hidden;
    background-color: #408aff;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .inpay-special::before {
    content: "";
    height: 1024px;
    background-image: url("../../../assets/images/inpay-special-dots.svg");
    background-repeat: repeat-y;
    background-size: 750px auto;
    opacity: 0.55;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  .image-div {
    width: 250px;
  }

  .inpay-special .phone-image {
    position: absolute;
    top: 650px;
    left: 45%;
    transform: translate(-50%, -50%);
  }

  .inpay-special-text-div {
    top: 0px;
    left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .inpay-special-text-title {
    font-family: "DM Sans";
    font-size: 28px;
    font-weight: 700;
    line-height: 78.12px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .inpay-special-text-subtitle {
    font-family: "DM Sans";
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }

  .inpay-special-text-body {
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }

  .inpay-special-text-button {
    position: absolute;
    width: 100%;
    left: 0;
    top: 325px;
    padding: 0;
  }

  .btn-des {
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 30.04px;
    color: #3a86ff;
  }

  .ibtn {
    display: flex;
    justify-content: center;
    width: max-content;
    height: max-content;
    padding: 6px 20px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .full-screen-image {
    width: 100;
    padding: 0;
    margin-left: -100px;
    margin-right: -100px;
  }

  .inpay-special {
    width: calc(100vw - 20px);
    margin-left: calc(-50vw + 50% + 10px);
    height: 350px;
    background-color: #408aff;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  .inpay-special::before {
    content: "";
    height: 864px;
    background-image: url("../../../assets/images/inpay-special-dots.svg");
    background-repeat: repeat;
    background-size: 750px auto;
    opacity: 0.55;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  .inpay-special .phone-image {
    width: 40%;
    position: absolute;
    top: 105px;
    right: 40px;
    z-index: 1;
  }

  .inpay-special-text-div {
    position: absolute;
    top: 15px;
    left: 40px;
    width: calc(100vw / 2.3);
    z-index: 1;
  }

  .inpay-special-text-title {
    font-family: "DM Sans";
    font-size: 32px;
    font-weight: 700;
    line-height: 78.12px;
    text-align: left;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .inpay-special-text-subtitle {
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #ffffff;
  }

  .inpay-special-text-body {
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #ffffff;
  }

  .inpay-special-text-button {
    z-index: 2;
    position: absolute;
    bottom: 15px;
    left: 40px;
  }
  .ibtn {
    width: 250px;
    height: 55px;
  }

  .btn-des {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 30.04px;
    color: #3a86ff;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .full-screen-image {
    width: 100;
    padding: 0;
    margin-left: -100px;
    margin-right: -100px;
  }

  .inpay-special {
    width: calc(100vw - 20px);
    margin-left: calc(-50vw + 50% + 10px);
    height: 400px;
    padding: 0;
    background-color: #408aff;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  .inpay-special::before {
    content: "";
    height: 864px;
    background-image: url("../../../assets/images/inpay-special-dots.svg");
    background-repeat: repeat;
    background-size: 750px auto;
    opacity: 0.55;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  .inpay-special .phone-image {
    width: 40%;
    position: absolute;
    top: 90px;
    right: 50px;
    z-index: 1;
  }

  .inpay-special-text-div {
    position: absolute;
    top: 30px;
    left: 85px;
    width: calc(100vw / 2.1);
    z-index: 1;
  }

  .inpay-special-text-title {
    font-family: "DM Sans";
    font-size: 40px;
    font-weight: 700;
    line-height: 78.12px;
    text-align: left;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .inpay-special-text-subtitle {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: #ffffff;
  }

  .inpay-special-text-body {
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #ffffff;
  }

  .inpay-special-text-button {
    z-index: 2;
    position: absolute;
    bottom: 20px;
    left: 85px;
  }

  .btn-des {
    font-family: "DM Sans";
    font-size: 23px;
    font-weight: 700;
    line-height: 30.04px;
    color: #3a86ff;
    position: relative;
    top: 9px;
  }
}
