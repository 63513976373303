
.call-to-action-img {
    width: calc(100vw - 20px);
    margin-left: calc(-50vw + 50% + 10px);
}



@media (min-width: 769px) and (max-width: 1023px) {
    .call-to-action-img {
        width: calc(100vw - 20px);
        margin-left: calc(-50vw + 50% + 10px);
    }
}


@media (min-width: 1024px) and (max-width: 1199px) {
    .call-to-action-img {
        width: calc(100vw - 20px);
        margin-left: calc(-50vw + 50% + 10px);
    }
}