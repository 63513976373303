.cb{
    font-size: 14px;
    font-weight: 500;
    line-height: 18.23px;
    text-align: center;
    color: #3A86FF;
    margin-bottom: 12px;
}
.sgb{
    font-size: 39px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 12px;
    font-family: "Montserrat", sans-serif;
}