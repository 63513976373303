.navbar-item{
    font-size: 14px;
    font-weight: 300;
    margin-left: 20px;
    margin-right: 20px;
}
.vempty{
    width:95.53px
}
.drawer{
    padding: 30px;
    padding-top: 45px;
}
.menu-head{
    padding-bottom: 45px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Montserrat';
}
.menu-item{
    padding-bottom: 25px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Montserrat';
    cursor: pointer;
}
.wl-app-logo{
    width: 10%;
}

.wl-title{
    font-family: 'DM Sans';
    font-size: 40px;
    font-weight: 600;
    line-height: 52.08px;
    text-align: center;
    color: #3A86FF;
}
.wl-subtitle{
    font-family: 'DM Sans';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #080808;

}

.wl-success-title{
    font-family: 'DM Sans';
    font-size: 43px;
    font-weight: 600;
    line-height: 52.08px;
    text-align: center;
    color: #3A86FF;
}
.wl-success-subtitle{
    font-family: 'DM Sans';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #080808;

}
.wl-center-image{
    display: flex;
    justify-self: center;
    width: 15%;
}
.dialog{
    border-radius: 100% !important;
    width: 65% !important;
    margin: auto;
    
}

.MuiDialog-container, .MuiDialog-paper{
    border-radius: 30px !important;
}

.emailField{
    border: 1px solid #94949463;
    height:60px;
    border-radius: 8px;
    max-width: 550px;
}
.email-roundBorder{
    border-radius: 8px;
}
.email-roundBorder::placeholder{
    color: #757474;
    font-weight: 500;
}
.waitlist-label{
    max-width: 550px;
    justify-self: start;
    text-align: left;
    font-family: 'DM Sans';
    font-size: 16px;
    font-weight: 500;
    position: relative;
    top: 10px;
}
.email-button{
    cursor: pointer;
    width: Hug (129px)px;
    height: 58px;
    padding: 8px 24px 8px 24px;
    border-radius: 8px;
    font-weight: 600;
    background-color: var(--Main, #3A86FF);
    color: white;
    transition: background-color 0.2s ease-in-out;
}
.email-button:hover{
    cursor: pointer;
    background-color: var(--Main, #3277e6)
}
.iw{
    max-width: 550px;
}
.wl-footer{
    color: #656464;
    font-weight: 500;
}

@media (max-width: 768px) {
.dialog{
    width: 100% !important;
}
.iw{
    max-width: 768px;
}
.waitlist-label{
    max-width: 768px;
}

.wl-app-logo{
    width: 35%;
}

.wl-title{
    font-family: 'DM Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 52.08px;
    text-align: center;
    color: #3A86FF;
}
.wl-subtitle{
    font-family: 'DM Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #080808;

}

.wl-success-title{
    font-family: 'DM Sans';
    font-size: 30px;
    font-weight: 700;
    line-height: 52.08px;
    text-align: center;
    color: #3A86FF;
}
.wl-success-subtitle{
    font-family: 'DM Sans';
    font-size: 19px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #080808;

}

.MuiDialog-container, .MuiDialog-paper, 
.MuiDialog-root {
    border-radius: 0px !important;
    margin: 0px;
    padding: 0px;
    width: 100%;
}
input::placeholder {
    font-size: 14px;
}
.wl-footer{
    color: #656464;
    font-weight: 500;
    font-size: 13px;
}
.App-subscribers{
    width: 14%;
}
.emailField{
    border: 1px solid #94949463;
    height:45px;
    border-radius: 8px;
    max-width: 550px;
}

.email-button{
    width: Hug (129px)px;
    height: 43px;
    padding: 4px 20px 4px 20px;
    border-radius: 8px;
    font-weight: 500;
    background: var(--Main, #3A86FF);
    color: white;
    font-size: 14px;
}
.wl-center-image{
    display: flex;
    justify-self: center;
    width: 30%;
}
}

@media (min-width: 769px) and (max-width: 1023px) {
    .navbar-item{
        font-size: 14px;
        font-weight: 300;
        margin-left: 20px;
        margin-right: 20px;
    }
    .vempty{
        width:95.53px
    }
    .drawer{
        padding: 30px;
        padding-top: 45px;
    }
    .menu-head{
        padding-bottom: 45px;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Montserrat';
    }
    .menu-item{
        padding-bottom: 25px;
        font-size: 18px;
        font-weight: 400;
        font-family: 'Montserrat';
        cursor: pointer;
    }
    .wl-app-logo{
        width: 10%;
    }
    
    .wl-title{
        font-family: 'DM Sans';
        font-size: 40px;
        font-weight: 600;
        line-height: 52.08px;
        text-align: center;
        color: #3A86FF;
    }
    .wl-subtitle{
        font-family: 'DM Sans';
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #080808;
    
    }
    
    .wl-success-title{
        font-family: 'DM Sans';
        font-size: 43px;
        font-weight: 600;
        line-height: 52.08px;
        text-align: center;
        color: #3A86FF;
    }
    .wl-success-subtitle{
        font-family: 'DM Sans';
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #080808;
    
    }
    .wl-center-image{
        display: flex;
        justify-self: center;
        width: 15%;
    }
    .dialog{
        border-radius: 100% !important;
        width: 65% !important;
        margin: auto;
        
    }
    
    .MuiDialog-container, .MuiDialog-paper{
        border-radius: 30px !important;
    }
    
    .emailField{
        border: 1px solid #94949463;
        height:60px;
        border-radius: 8px;
        max-width: 550px;
    }
    .email-roundBorder{
        border-radius: 8px;
    }
    .email-roundBorder::placeholder{
        color: #757474;
        font-weight: 500;
    }
    .waitlist-label{
        max-width: 550px;
        justify-self: start;
        text-align: left;
        font-family: 'DM Sans';
        font-size: 16px;
        font-weight: 500;
        position: relative;
        top: 10px;
    }
    .email-button{
        cursor: pointer;
        width: Hug (129px)px;
        height: 58px;
        padding: 8px 24px 8px 24px;
        border-radius: 8px;
        font-weight: 600;
        background-color: var(--Main, #3A86FF);
        color: white;
        transition: background-color 0.2s ease-in-out;
    }
    .email-button:hover{
        cursor: pointer;
        background-color: var(--Main, #3277e6)
    }
    .iw{
        max-width: 550px;
    }
    .wl-footer{
        color: #656464;
        font-weight: 500;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .navbar-item{
        font-size: 14px;
        font-weight: 300;
        margin-left: 20px;
        margin-right: 20px;
    }
    .vempty{
        width:95.53px
    }
    .drawer{
        padding: 30px;
        padding-top: 45px;
    }
    .menu-head{
        padding-bottom: 45px;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Montserrat';
    }
    .menu-item{
        padding-bottom: 25px;
        font-size: 18px;
        font-weight: 400;
        font-family: 'Montserrat';
        cursor: pointer;
    }
    .wl-app-logo{
        width: 10%;
    }
    
    .wl-title{
        font-family: 'DM Sans';
        font-size: 40px;
        font-weight: 600;
        line-height: 52.08px;
        text-align: center;
        color: #3A86FF;
    }
    .wl-subtitle{
        font-family: 'DM Sans';
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #080808;
    
    }
    
    .wl-success-title{
        font-family: 'DM Sans';
        font-size: 43px;
        font-weight: 600;
        line-height: 52.08px;
        text-align: center;
        color: #3A86FF;
    }
    .wl-success-subtitle{
        font-family: 'DM Sans';
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        color: #080808;
    
    }
    .wl-center-image{
        display: flex;
        justify-self: center;
        width: 15%;
    }
    .dialog{
        border-radius: 100% !important;
        width: 65% !important;
        margin: auto;
        
    }
    
    .MuiDialog-container, .MuiDialog-paper{
        border-radius: 30px !important;
    }
    
    .emailField{
        border: 1px solid #94949463;
        height:60px;
        border-radius: 8px;
        max-width: 550px;
    }
    .email-roundBorder{
        border-radius: 8px;
    }
    .email-roundBorder::placeholder{
        color: #757474;
        font-weight: 500;
    }
    .waitlist-label{
        max-width: 550px;
        justify-self: start;
        text-align: left;
        font-family: 'DM Sans';
        font-size: 16px;
        font-weight: 500;
        position: relative;
        top: 10px;
    }
    .email-button{
        cursor: pointer;
        width: Hug (129px)px;
        height: 58px;
        padding: 8px 24px 8px 24px;
        border-radius: 8px;
        font-weight: 600;
        background-color: var(--Main, #3A86FF);
        color: white;
        transition: background-color 0.2s ease-in-out;
    }
    .email-button:hover{
        cursor: pointer;
        background-color: var(--Main, #3277e6)
    }
    .iw{
        max-width: 550px;
    }
    .wl-footer{
        color: #656464;
        font-weight: 500;
    }
}