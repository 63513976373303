.header-subtitle {
    font-family: 'DM Sans';
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
}
@media (max-width:768px) {
    .header-subtitle {
        font-family: 'DM Sans';
        font-size: 26px;
        font-weight: 600;
        line-height: 30px;
    }
}