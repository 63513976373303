.about-subtitle {
    font-family: 'DM Sans';
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
}

.about-title {
    font-family: 'DM Sans';
    font-size: 36px;
    font-weight: 700;
    line-height: 46.87px;
}

@media (max-width:768px) {
    .about-title {
        font-family: 'DM Sans';
        font-size: 26px;
        font-weight: 700;
        line-height: 46.87px;
    }
}