.cb {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.23px;
  text-align: center;
  color: #3a86ff;
  margin-bottom: 12px;
}
.gq {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 6px;
  font-family: "Montserrat", sans-serif;
}
.gta {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
}

.faq-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 20px;
  background-color: #eff5ff;
  width: 100%;
  margin-bottom: 20px;
  transition: 0.4s;
}

.faq-container.answer {
  background-color: #3a86ff;
}

.faq-question-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
}

.faq-question {
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  max-width: 700px;
  font-family: "DM Sans";
}
.faq-answer {
  width: 85%;
  font-size: 22px;
  font-weight: 500;
  line-height: 41.66px;
  text-align: left;
  display: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin-top: 40px;
  color: white;
  padding: 5px 20px 15px;
}

.faq-answer-show {
  display: block;
  max-height: 500px;
}

.faq-arrow {
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.faq-arrow:hover {
  transform: scale(1.01);
}

@media (min-width: 0px) and (max-width: 400px) {
  .faq-container {
    display: flex;
    flex-direction: column;
    padding: 2px 10px;
    border-radius: 12px;
    background-color: #eff5ff;
    width: 100%;
    margin-bottom: 20px;
    transition: 0.4s;
  }
  .faq-container.answer {
    background-color: #3a86ff;
  }
  .faq-question-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px;
    cursor: pointer;
  }
  .faq-question {
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    max-width: 700px;
    font-family: "DM Sans";
  }
  .faq-arrow {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .faq-answer {
    width: 85%;
    font-size: 12px;
    font-weight: 500;
    line-height: 26.66px;
    text-align: left;
    margin-top: 10px;
    padding: 5px 10px 15px;
  }
}

@media (max-width: 768px) {
  .faq-container {
    display: flex;
    flex-direction: column;
    padding: 2px 10px;
    border-radius: 12px;
    background-color: #eff5ff;
    width: 100%;
    margin-bottom: 20px;
    transition: 0.4s;
  }
  .faq-container.answer {
    background-color: #3a86ff;
  }
  .faq-question-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px;
    cursor: pointer;
  }
  .faq-question {
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    max-width: 700px;
    font-family: "DM Sans";
  }
  .faq-arrow {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .faq-answer {
    width: 85%;
    font-size: 12px;
    font-weight: 500;
    line-height: 26.66px;
    text-align: left;
    margin-top: 10px;
    padding: 5px 10px 15px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .faq-container {
    display: flex;
    flex-direction: column;
    padding: 2px 10px;
    border-radius: 12px;
    background-color: #eff5ff;
    width: 100%;
    margin-bottom: 20px;
    transition: 0.4s;
  }
  .faq-container.answer {
    background-color: #3a86ff;
  }
  .faq-question-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px;
    cursor: pointer;
  }
  .faq-question {
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    max-width: 700px;
    font-family: "DM Sans";
  }
  .faq-arrow {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .faq-answer {
    width: 85%;
    font-size: 12px;
    font-weight: 500;
    line-height: 26.66px;
    text-align: left;
    margin-top: 10px;
    padding: 5px 10px 15px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .faq-container {
    display: flex;
    flex-direction: column;
    padding: 2px 10px;
    border-radius: 12px;
    background-color: #eff5ff;
    width: 100%;
    margin-bottom: 20px;
    transition: 0.4s;
  }
  .faq-container.answer {
    background-color: #3a86ff;
  }
  .faq-question-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px;
    cursor: pointer;
  }
  .faq-question {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    max-width: 700px;
    font-family: "DM Sans";
  }
  .faq-arrow {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .faq-answer {
    width: 85%;
    font-size: 14px;
    font-weight: 500;
    line-height: 26.66px;
    text-align: left;
    margin-top: 10px;
    padding: 5px 8px 15px;
  }
}
