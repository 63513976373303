.roll-of-logos{
    background: #F0F6FF;
    margin-top: 65px;
    margin-bottom: 65px;
    padding: 38px;
    padding-right: 0px;
    padding-left: 0px;
    text-align: center;
    align-items: center;
    font-size: 17px;
    line-height: 24.3px;
}
.iscroll {
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
}
.iscroll .scroll {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: fit-content;
    white-space: nowrap;
    animation: loop 30s linear infinite;
}
.fade {
    width: 100%;
    position: absolute;
    background: linear-gradient(
        90deg,
        #BBD4FC,
        transparent 0%,
        transparent 90%,
        #BBD4FC
    );
    inset: 0;
}
@keyframes loop {
    0% {transform: translateX(0%);}
    100% {transform: translateX(-50%);}
}
.scroll-item {
    white-space: nowrap;
    padding-right: 100px;
}

@media (max-width: 768px) {
.isl{
    /* font-size: 18px;
    font-weight: 500; */
}
}
@media (min-width: 760px) and (max-width: 1024px) {
    .roll-of-logos{
        background: #F0F6FF;
        margin-top: 65px;
        margin-bottom: 65px;
        padding: 25px;
        padding-right: 0px;
        padding-left: 0px;
        text-align: center;
        align-items: left;
        font-size: 15px;
        line-height: 24.3px;
    }
    .iscroll {
        width: 100%;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
    }
    .iscroll .scroll {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: fit-content;
        white-space: nowrap;
        animation: loop 30s linear infinite;
    }
    .fade {
        width: 100%;
        position: absolute;
        background: linear-gradient(
            90deg,
            #BBD4FC,
            transparent 0%,
            transparent 90%,
            #BBD4FC
        );
        inset: 0;
    }
    @keyframes loop {
        0% {transform: translateX(0%);}
        100% {transform: translateX(-50%);}
    }
    .scroll-item {
        white-space: nowrap;
        padding-right: 70px;
    }
    .isl{
     
        text-align: center;
    }
}


@media (min-width: 1024px) and (max-width: 1199px) {}