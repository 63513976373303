.ihero-div {
  border-radius: 20px;
  background-color: #135bcd;
  color: #fff;
  padding: 5% 4.6% 0px 4.3%;
}
.ihero-text {
  font-size: 65px;
  font-weight: 600;
  line-height: 70.52px;
  letter-spacing: -0.01em;
  text-align: left;
}
.ihero-des {
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.hero-button-div {
  margin-bottom: 0;
}
.iflex-end {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
}
.app-logo-appstore {
  margin-left: 20px;
}

.app-logo-appstore,
.app-logo-playstore,
.phn {
  margin-top: 5px;
  margin-bottom: 23px;
}

.btn-extra-large {
  font-size: 1.5rem;
  width: 300px;
  height: 80px;
  padding: 25px;
  gap: 0px;
  border-radius: 12px;
  opacity: 0px;
}
.phn {
  width: calc(100vw / 4);
  margin-left: 40px;
}

.hero-btn-des {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 30.04px;
  color: #3a86ff;
}

.hero-button {
  width: 60%;
}

.hero-ibtn {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 10px 20px;
  border-radius: 12px;
}

.store-img {
  width: 24px;
  height: 24px;
}
.store-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1px;
  align-items: center;
}

.store-text-wrap .subtitle {
  font-size: 10px;
  color: #475467;
  font-family: Inter;
  font-weight: 400;
  line-height: 14px;
}

.store-text-wrap .title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #3a86ff;
}

@media (max-width: 768px) {
  .hero-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-ibtn {
    display: flex;
    padding: 8px 20px;
  }
  .hero-btn-des {
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 30.04px;
    color: #3a86ff;
  }

  .ihero-text {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    margin-top: 15px;
  }
  .ihero-des {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .app-logo-appstore,
  .app-logo-playstore {
    width: 45%;
  }

  .phn {
    width: 100%;
    margin-left: 0px;
  }
  .btn-extra-large {
    font-size: 16px;
    width: 100%;
    height: 54px;
    padding: 13px;
    gap: 0px;
    border-radius: 12px;
    opacity: 0px;
  }

  .btn-des {
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 26.04px;
    background-color: white;
    color: #3a86ff;
  }
}
.whiteBg {
  background-color: white;
}

@media (min-width: 769px) and (max-width: 1023px) {
  .ibtn {
    padding: 5px;
    background-color: white;
    width: 280px;
  }
  .ihero-div {
    position: relative;
    border-radius: 20px;
    background-color: #135bcd;
    color: #fff;
    padding: 5% 4.6% 0px 4.3%;
  }
  .ihero-text {
    font-size: 28px;
    font-weight: 600;
    line-height: 43.52px;
    letter-spacing: -0.01em;
    text-align: left;
    width: calc(100vw / 2.5);
  }
  .ihero-des {
    font-size: 16px;
    width: calc(100vw / 2.5);
    font-weight: 400;
    line-height: 26px;
    text-align: left;
  }

  .hero-button-div {
    margin-bottom: 0;
  }
  .phn {
    position: absolute;
    bottom: 5px;
    width: calc(100vw / 4.5);
    right: 50px;
    height: calc(100vw / 4.5);
  }

  .hero-button {
    width: 100%;
  }

  .hero-ibtn {
    display: flex;
  }
  .hero-btn-des {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 30.04px;
    color: #3a86ff;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .ihero-text {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-top: 15px;
  }
  .ihero-des {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
  }

  .hero-button {
    width: 100%;
  }

  .hero-ibtn {
    display: flex;
  }

  .app-logo-appstore,
  .app-logo-playstore {
    width: 45%;
  }

  .phn {
    width: 100%;
    margin-left: 0px;
  }
  .btn-extra-large {
    font-size: 16px;
    width: 100%;
    height: 54px;
    padding: 13px;
    gap: 0px;
    border-radius: 12px;
    opacity: 0px;
  }

  .hero-btn-des {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 26.04px;
    background-color: white;
    color: #3a86ff;
  }
}
