.ibody-text {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 400;
    line-height: 45px;
    text-align: left;
    justify-content: center;
    width: 80%;
}

.ibody-title{
    font-family: "DM Sans";
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    justify-content: center;
    width: 80%;  
}

.inpay-container {
    width: 100%;
    height: Hug(475px)px;
    background: #3A86FF;
    position: relative;
    padding-bottom: 40px;
    overflow: hidden;

}

.vector-outline {
    position: absolute;
    left: 0px;
    top: 300px;
    transform: scaleX(-1);
}

.inpay-container-title{
    font-family: "DM Sans";
    font-size: 32px;
    font-weight: 700;
    line-height: 41.66px;
    text-align: center;
    color: #ffffff;
    
}

.rounded-number{
    width: 24px;
    height: 24px;
    padding: 2.75px 8.63px 3.25px 8.38px;
    gap: 0px;
    border-radius: 37.5px;
    opacity: 0px;
    background: #FFFFFF;

}

.rounded-number p {
    font-size: 13px;
    justify-self: center;
    justify-content: center;
    font-weight: 600;
}

.inpay-container-title2{
    font-family: "DM Sans";
    font-size: 24px;
    font-weight: 600;
    line-height: 31.25px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 20px;
}


.inpay-container-text {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    width: 85%;
    color: #ffffff;  
}

@media (max-width: 768px) {

    .ibody-text {
        font-family: "DM Sans";
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        justify-content: center;
        width: 90%;
    }
    
    .ibody-title{
        font-family: "DM Sans";
        font-size: 26px;
        font-weight: 700;
        line-height: 45px;
        text-align: left;
        justify-content: center;
        width: 90%;  
    }
    .inpay-container {
        width: 100%;
        height: auto;
        background: #3A86FF;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
    
    }

    .vector-outline {
        position: absolute;
        height: 500px;
        width: 500px;
        top: -350px;
        left: 45%;
        transform: scaleY(-1);

    }

    .inpay-container-title{
        font-family: "DM Sans";
        font-size: 24px;
        font-weight: 600;
        padding-left: 10px;
        line-height: 41.66px;
        text-align: left;
        color: #ffffff;
    }
    
    .rounded-number{
        width: 25px;
        height: 25px;
        border-radius: 37.5px;
        opacity: 0px;
        background: #FFFFFF;
        text-align: center;
    }
    
    .rounded-number p {
        font-size: 15px;
        justify-self: center;
        justify-content: center;
        position: relative;
        bottom: 2px;
        right: 1px;
    }
    
    .inpay-container-title2{
        font-family: "DM Sans";
        font-size: 20px;
        font-weight: 700;
        line-height: 26.04px;
        text-align: left;
        color: #ffffff;
    }
    
    
    .inpay-container-text {
        font-family: "DM Sans";
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #ffffff;  
        padding-bottom: 40px;
        width: 100%;

    }
}

@media (min-width: 769px) and (max-width: 1023px) {

    .ibody-text {
        font-family: "DM Sans";
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        justify-content: center;
        width: 90%;
    }
    
    .ibody-title{
        font-family: "DM Sans";
        font-size: 26px;
        font-weight: 700;
        line-height: 45px;
        text-align: left;
        justify-content: center;
        width: 90%;  
    }
    .inpay-container {
        width: 100%;
        height: auto;
        background: #3A86FF;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
    
    }

    .vector-outline {
        position: absolute;
        height: 500px;
        width: 500px;
        top: -320px;
        left: 600px;
        transform: scaleY(-1);

    }

    .inpay-container-title{
        font-family: "DM Sans";
        font-size: 24px;
        font-weight: 600;
        padding-left: 10px;
        line-height: 41.66px;
        text-align: center;
        color: #ffffff;
    }
    
    .rounded-number{
        width: 25px;
        height: 25px;
        border-radius: 37.5px;
        opacity: 0px;
        background: #FFFFFF;
        text-align: center;
    }
    
    .rounded-number p {
        font-size: 15px;
        justify-self: center;
        justify-content: center;
        position: relative;
        bottom: 2px;
        right: 1px;
    }
    
    .inpay-container-title2{
        font-family: "DM Sans";
        font-size: 20px;
        font-weight: 700;
        line-height: 26.04px;
        text-align: left;
        color: #ffffff;
    }
    
    
    .inpay-container-text {
        font-family: "DM Sans";
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #ffffff;  
        padding-bottom: 40px;
        width: 100%;

    }
}

@media (min-width: 1024px) and (max-width: 1199px) {

    .ibody-text {
        font-family: "DM Sans";
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        justify-content: center;
        width: 90%;
    }
    
    .ibody-title{
        font-family: "DM Sans";
        font-size: 26px;
        font-weight: 700;
        line-height: 45px;
        text-align: left;
        justify-content: center;
        width: 90%;  
    }
    .inpay-container {
        width: 100%;
        height: auto;
        background: #3A86FF;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
    
    }

    .vector-outline {
        position: absolute;
        height: 500px;
        width: 500px;
        top: -320px;
        left: 750px;
        transform: scaleY(-1);

    }

    .inpay-container-title{
        font-family: "DM Sans";
        font-size: 24px;
        font-weight: 600;
        padding-left: 10px;
        line-height: 41.66px;
        text-align: center;
        color: #ffffff;
    }
    
    .rounded-number{
        width: 25px;
        height: 25px;
        border-radius: 37.5px;
        opacity: 0px;
        background: #FFFFFF;
        text-align: center;
    }
    
    .rounded-number p {
        font-size: 15px;
        justify-self: center;
        justify-content: center;
        position: relative;
        bottom: 2px;
        right: 1px;
    }
    
    .inpay-container-title2{
        font-family: "DM Sans";
        font-size: 20px;
        font-weight: 700;
        line-height: 26.04px;
        text-align: left;
        color: #ffffff;
    }
    
    
    .inpay-container-text {
        font-family: "DM Sans";
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #ffffff;  
        padding-bottom: 40px;
        width: 100%;

    }
}