* {
  font-family: "Inter", sans-serif;
}
.icontainer {
  margin-left: 9.4%;
  margin-right: 9.4%;
}
.icontainer2 {
  margin-left: 7%;
  margin-right: 7%;
}

/* test */
.red {
  background-color: red;
}
.white-bg {
  background-color: white;
}

.center-cross {
  align-content: center;
}
.dm-sans {
  font-family: "DM+Sans";
}
.montserrat {
  font-family: "Montserrat";
}
.poppins {
  font-family: "Poppins";
}
body,
html {
  /* width: 100vw; */
}

@media (max-width: 768px) {
  .icontainer,
  .icontainer2 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
