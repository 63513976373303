.tcontainer{
    border-radius: 24px;
    width: 600px;
    height: 420px;
    border: 1px solid rgba(148, 148, 148, 0.50);
    background: var(--Hov-70, rgba(187, 212, 252, 0.70));
}
.tcontainer2{
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background: #BBD4FC;
    border-top: 1px solid rgba(148, 148, 148, 0.50);
    padding-top: 4px; 
    padding-bottom: 4px;

}



.tesimonial-des{
    padding-top:10px;
    padding-bottom: 50px;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    max-width: 552px;
    font-family: 'Montserrat';
}

.tmedia {
    display: flex;
    justify-content: center;
    align-items: center; 
}


.tmedia-body{
font-size: 20px;
font-weight: 700;
line-height: 16.9px;
text-align: center;
}
.tmedia-des{
font-size: 15.5px;
font-weight: 300;
line-height: 21.6px;
text-align: center;
}
.tmedia-img{
    margin-top: 40px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: block;
}
.tmedia-info{
    margin-top: 5px;
}




@media (max-width: 768px) {
    .tesimonial-des{
        padding: 10px;
        padding-top:50px;
        padding-bottom: 20px;
        font-size: 15px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        max-width: 680px;
        font-family: 'Montserrat';
    }
}

@media (max-width: 1024px) {
    .tcontainer{
        border-radius: 24px;
        width: 100%;
        height: 520px;
        border: 1px solid rgba(148, 148, 148, 0.50);
        background: var(--Hov-70, rgba(187, 212, 252, 0.70));
    }
}



