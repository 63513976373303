.toc {
  position: fixed;
  top: 100px;
  max-width: 250px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 14px;
  max-height: 100vh;
  overflow-y: auto;
}

.toc ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.toc-item {
  margin: 5px 0;
  cursor: pointer;
  padding-left: 10px;
}

.toc-h2 {
  font-weight: bold;
}

.toc-h3 {
  font-weight: normal;
  padding-left: 20px;
}

.toc-item.active {
  color: #007bff;
}

@media (max-width: 768px) {
  .toc {
    position: relative;
    top: 100px;
    width: 100vw;
    max-width: 80%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-size: 14px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .toc {
    position: fixed;
    top: 100px;
    max-width: 180px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-size: 14px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .toc {
    position: fixed;
    top: 100px;
    max-width: 180px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-size: 14px;
  }
}
