.page-toc-wrap {
  position: relative;
}

.privacy-page-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.privacy-text-wrap {
  margin-left: 180px;
  max-width: 800px;
}

.privacy-text-wrap h2 {
  margin: 20px 0px;
}
.privacy-text-wrap h3 {
  font-size: 24px;
  margin: 15px 0px;
}

.privacy-text-wrap h4 {
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 500;
  color: rgb(37, 37, 37);
}

.privacy-text-wrap table.requirements {
  margin: 20px 0px;
}

.privacy-text-wrap table.requirements thead th,
.privacy-text-wrap table.requirements tbody td {
  padding: 5px 10px;
  min-width: 180px;
  font-size: 16px;
  font-family: "DM Sans";
  border-width: 1px !important;
}

.privacy-text-wrap p,
.privacy-text-wrap li {
  line-height: 26px;
  font-size: 16px;
}

.privacy-text-wrap ol.lower-alpha {
  list-style-type: lower-alpha;
  margin-top: 10px;
}

.privacy-text-wrap ul.bullet-outline {
  list-style-type: circle;
}

.privacy-text-wrap li {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .privacy-page-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    padding: 20px;
  }

  .privacy-text-wrap {
    max-width: 100vw;
    margin-left: 0px;
    padding: 0 20px;
  }

  .privacy-text-wrap h2 {
    margin: 14px 0px;
    font-size: 22px;
  }
  .privacy-text-wrap h3 {
    font-size: 18px;
    margin: 10px 0px;
  }

  .privacy-text-wrap h4 {
    margin: 6px 0px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(37, 37, 37);
  }

  .privacy-text-wrap table.requirements {
    margin: 20px 0px;
  }

  .privacy-text-wrap table.requirements thead th,
  .privacy-text-wrap table.requirements tbody td {
    padding: 5px 10px;
    min-width: 180px;
    font-size: 12px;
    font-family: "DM Sans";
    border-width: 1px !important;
  }

  .privacy-text-wrap p,
  .privacy-text-wrap li {
    line-height: 22px;
    font-size: 12px;
  }

  .privacy-text-wrap ol.lower-alpha {
    list-style-type: lower-alpha;
    margin-top: 10px;
  }

  .privacy-text-wrap ul.bullet-outline {
    list-style-type: circle;
  }

  .privacy-text-wrap li {
    margin-top: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .privacy-page-container {
    margin-top: 100px;
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .privacy-text-wrap {
    margin-left: 0px;
    max-width: 600px;
    padding: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .privacy-page-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .privacy-text-wrap {
    max-width: 700px;
    padding: 20px;
  }
}
