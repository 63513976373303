.info {
    width: 100%;
    top: 285px;
    padding: 30px 231.93px 30.81px 220px;
    gap: 0px;
    background: #3A86FF;

}

.info-title {
    font-family: "DM Sans";
    font-size: 32px;
    font-weight: 700;
    line-height: 41.66px;
    text-align: left;    
    color: white;
}

.info-subtitle {
    font-family: "DM Sans";
    color: white;
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
    padding-top: 14px;
    
}
@media (max-width:768px) {
    .info {
        width: 100%;
        top: 285px;
        padding: 15px 20px 15px 20px;
        gap: 0px;
        background: #3A86FF;
    
    }
    .info-title{
        font-family: 'DM Sans';
        font-size: 18px;
        font-weight: 700;
        line-height: 16.23px;
        text-align: left;
        padding-top: 15px;
        
    }
    .info-subtitle {
        font-family: "DM Sans";
        color: white;
        font-size: 15px;
        font-weight: 300;
        line-height: 26px;
        text-align: left;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}